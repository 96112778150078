import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import * as z from "zod";
import { useState } from "react";
import { loginSchema } from "../../lib/validationSchema/validation";
import api from "src/services/api";
import { useAuth } from "src/context/authContext";
import PageRoutes from "src/constants/pageRoutes";
import { exceptionHandler } from "src/utils/helper";
import { ErrorMessages } from "src/constants/errorMessages";
import { useAlertStore } from "src/store/alert";
import AuthWrapper from "src/modules/authWrapper";

const Login = ({ isPopUp }: any) => {
  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { setAlert } = useAlertStore();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const onSubmit = form.handleSubmit(async (data) => {
    try {
      setIsLoggingIn(true);
      const res = await api.auth.login({ ...data });
      if (res.status == 200) {
        if (res.data?.resetToken) {
          navigate(`${PageRoutes.RESET_PASSWORD}/?token=${res.data?.resetToken}`);
        } else {
          login(res.data);
          navigate(PageRoutes.HOME);
        }
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error: any) {
      exceptionHandler(error, setAlert, "destructive");
    } finally {
      setIsLoggingIn(false);
    }
  });

  return (
    <AuthWrapper>
      <div className="flex flex-1 p-[20px] justify-center w-full centered shrink h-[100vh] ">
        <div
          className={`max-w-[400px] w-full flex flex-col justify-center custom-container-two
           gap-[24px] md:gap-[40px]
        `}
        >
          <div>
            <h2
              className={
                "text-[24px] md:text-[28px] xl:text-[32px] text-center leading-[42px]  font-GothamBold text-[#000] mb-[0px]"
              }
            >
              Login
            </h2>
            <p className="font-sans text-[#4A4F55] text-[16px] leading-[24px] mt-5">
              Happy to see you.
              <br /> Log in to your account below.
            </p>
          </div>
          <Form {...form}>
            <form
              onSubmit={onSubmit}
              className="[&_.error-msg]:text-[12px]  [&_.error-msg]:text-[#bf1332] 
            [&_.error-msg]:leading-5 [&_.error-msg]:font-sans [&_.reply-input>input]:text-[#4A4F55] [&_.reply-input>input]:placeholder:text-[14px] [&_.form-label]:text-[14px] [&_.form-label]:text-[#4A4F55] [&_.form-label]:font-normal [&_.form-label]:font-sans 
            [&_.form-label]:leading-5 [&_.form-label]:text-start [&_.form-itm]:mb-5 [&_.form-itm]:text-start"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="form-itm">
                    <FormLabel htmlFor="email" className="form-label">
                      Email Address
                      <sup className="text-[#AD2F33] text-[14px] align-text-bottom font-sans">*</sup>
                    </FormLabel>

                    <Input
                      maxLength={50}
                      id="email"
                      disabled={isLoggingIn}
                      className={`reply-input ${form.formState.errors.email ? "border-[#bf1332]" : "border-[#d6d6d6]"}`}
                      placeholder="Enter Email Address"
                      {...field}
                      onChange={(event: any) => {
                        const inputValue = event.target.value;
                        const cleanedValue = inputValue.length > 0 ? inputValue.trimStart() : inputValue; // Trim leading spaces
                        field.onChange(cleanedValue);
                      }}
                    />
                    <FormMessage className="error-msg" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <>
                    <FormItem className="form-itm">
                      {!isPopUp && (
                        <FormLabel htmlFor="password" className="form-label">
                          Your Password
                          <sup className="text-[#AD2F33] text-[14px] align-text-bottom font-sans">*</sup>
                        </FormLabel>
                      )}
                      <Input
                        id="password"
                        disabled={isLoggingIn}
                        className={`reply-input ${form.formState.errors.password ? "border-[#bf1332]" : "border-[#d6d6d6]"}`}
                        placeholder="Password"
                        maxLength={25}
                        {...field}
                        endIcon={
                          <img
                            tabIndex={0}
                            src={require(
                              "../../assets/images/login/" + (showPassword ? "show-pass" : "hide-pass") + ".svg"
                            )}
                            height={16}
                            width={16}
                            alt="eye"
                            className="cursor-pointer ml-[5px] eyeicon"
                            onClick={togglePasswordVisibility}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                togglePasswordVisibility();
                              }
                            }}
                          />
                        }
                        type={showPassword ? "text" : "password"}
                        onChange={(event: any) => {
                          const cleanedValue = event.target.value.trim();
                          field.onChange(cleanedValue);
                        }}
                      />
                      <FormMessage className="error-msg" />
                    </FormItem>
                    <p className="bg-[#F4F4F4] text-[12px] p-[10px] rounded-[5px] font-sans text-[#4A4F55] mb-[20px] text-left">
                      Password must contain a minimum of 8 characters, at least 1 letter, 1 number, and 1 special
                      character (!,@,#,$,%,^,&,*).
                    </p>
                  </>
                )}
              />
              <div className="text-end ">
                <Link
                  to={PageRoutes.FORGOT_PASSWORD}
                  className="text-[#D06F1A] text-[14px] leading-[20px]  font-GothamBold hover:underline"
                >
                  Forgot Password?
                </Link>
              </div>
              <div>
                <Button
                  type="submit"
                  className={`w-full uppercase font-bold leading-5 rounded-[200px] py-[15px] px-[30px] text-[16px] h-[50px] my-[30px] font-GothamBold
                `}
                  disabled={isLoggingIn}
                >
                  {isLoggingIn ? `Logging In...` : `Log in`}
                </Button>
                <div className="text-center">
                  <p className="font-sans text-[14px] text-[#4A4F55] leading-5 mb-[30px]">
                    Don't have an account?{" "}
                    <span className="hover:underline text-[#D06F1A] font-GothamBold ">
                      <Link to={PageRoutes.REGISTER}>Create an Account</Link>
                    </span>
                  </p>
                  <p className="font-sans text-[14px] text-[#4A4F55]">© 2024 The Beer Store</p>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </AuthWrapper>
  );
};
export default Login;
