import React, { ChangeEvent, useRef, useState } from "react";
import { Button } from "../../components/ui/button";
import DownloadIcon from "../../assets/images/downloadIcon.svg";
import UploadIcon from "../../assets/images/uploadIcon.svg";
import { FormField, FormItem, FormMessage } from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { downloadFileByUrl } from "src/utils/helper";
import { UseFormReturn } from "react-hook-form";
import { programSchema } from "src/lib/validationSchema/validation";
import { z } from "zod";

interface Props {
  url: string;
  form: UseFormReturn<z.infer<typeof programSchema>>;
}

export default function ParticipatingStore({ url, form }: Props) {
  const [fileName, setFileName] = useState<string>(""); // State to store the selected file name
  const uploadFileInputRef = useRef<HTMLInputElement>(null);

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files;
    if (file?.length) {
      setFileName(file[0].name); // Set the file name when a file is selected
      form.setValue("storesFile", file[0]);
      form.clearErrors("storesFile");
    }
  }

  const handleIconClick = () => {
    if (uploadFileInputRef.current) {
      uploadFileInputRef.current.click();
    }
  };

  return (
    <>
      <div className="flex flex-col gap-[40px]">
        <div className="flex flex-col gap-[30px]">
          <div className="flex gap-5 justify-start">
            <h2 className="mb-0 text-[24px] font-GothamBold">
              Select Participating Stores
            </h2>
          </div>

          <div className="setpsmaindiv flex flex-col sm:flex-row mdtab:flex-col xxl:flex-row gap-[20px] mb-[0px]">
            <div className="flex flex-row justify-between gap-[20px]">
              <div className="stepone border-[1px] rounded-[10px] border-[#D6D6D6] py-[30px] px-[20px] basis-1/2 justify-start items-center flex flex-col">
                <span className="text-[16px] leading-[24px] text-[#000] mb-[10px] font-GothamMedium uppercase">
                  Step 1
                </span>
                <p className="text-[14px] leading-[20px] font-normal text-[#000] mb-[10px] text-center w-[95%]">
                  Download the store list
                </p>
                <Button
                  variant={"outlineyellow"}
                  onClick={() => downloadFileByUrl(url)}
                  className="rounded-[30px] gap-[10px] bg-white hover:bg-white h-[45px] mdtab:px-[8px] lg-1100:px-[16px]"
                  type="button"
                >
                  <span className="font-GothamMedium uppercase py-[0px] text-[13px] leading-[17px] mdtab:text-[10px] mdtab:leading-[14px] lg:text-[11px] lg:leading-[15px] xl:text-[14px] xl:leading-[20px]">
                    Download List
                  </span>
                  <span>
                    <img
                      src={DownloadIcon}
                      width={10}
                      height={14}
                      alt="DownloadIcon"
                    />
                  </span>
                </Button>
              </div>
              <div className="steptwo border-[1px] rounded-[10px] border-[#D6D6D6] py-[30px] px-[20px] basis-1/2 justify-start items-center flex flex-col">
                <span className="text-[16px] leading-[24px] text-[#000] mb-[10px] font-GothamMedium uppercase">
                  Step 2
                </span>
                <p className="text-[14px] leading-[20px] font-normal text-[#000] mb-[0px] text-center w-full">
                  In the Excel spreadsheet, select the stores for this program,
                  and save{" "}
                </p>
              </div>
            </div>
            <div className="stepthree border-[1px] rounded-[10px] border-[#D6D6D6] py-[30px] px-[12px] md-820:px-[20px] basis-1/2 justify-start items-center flex flex-col">
              <span className="text-[16px] leading-[24px] text-[#000] mb-[10px] font-GothamMedium uppercase">
                Step 3
              </span>
              <p className="text-[14px] leading-[20px] font-normal text-[#000] mb-[10px] text-center w-[95%]">
                Upload the store list (must be CSV format)
              </p>
              <>
                <FormField
                  name="storesFile"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <>
                        <div className="flex flex-col">
                          <FormItem className="border-[2px] rounded-[50px] border-[#f4b233] flex flex-row relative cursor-pointer h-[45px] [&_input]:cursor-pointer [&_input]:w-full [&_input#file-upload-button]:cursor-pointer">
                            <Input
                              {...field}
                              ref={uploadFileInputRef}
                              value={undefined}
                              type="file"
                              onChange={handleFileChange}
                              accept="text/csv"
                              className="absolute opacity-0 h-full w-full"
                            />

                            <div className="flex flex-row items-center pl-[20px] pr-[20px] !m-0">
                              {/* Display file name if selected, otherwise show "Choose File" */}
                              <p className="font-GothamMedium text-[10px] md-820:text-[12px] xl:text-[14px] mr-[8px] !cursor-pointer">
                                Choose File
                              </p>

                              <div className="flex items-center mr-[5px] cursor-pointer" onClick={handleIconClick}>
                                <span className="font-Gotham text-[10px] w-[80px] overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer">
                                  {fileName ? fileName : "No File chosen"}
                                </span>
                                <img
                                  src={UploadIcon}
                                  width={10}
                                  height={14}
                                  alt="UploadIcon"
                                  className="absolute right-[11px] top-[14px] !mt-0 ml-[5px] cursor-pointer"
                                  // onClick={() => handleIconClick()}
                                />
                              </div>
                            </div>
                             {/* <label htmlFor="fileInput">
                              <img
                                src={UploadIcon}
                                width={10}
                                height={14}
                                alt="UploadIcon"
                                className="absolute right-[11px] top-[15px] !mt-0 ml-[5px] cursor-pointer"
                              />
                            </label>
                            <input
                              id="fileInput"
                              type="file"
                              style={{ display: "none" }} // Hide the actual input visually
                              onChange={handleFileChange}
                            /> */}
                          </FormItem>
                          {form.watch("storeListCsvUrl") && (
                            <p className="text-[14px] leading-[20px] font-normal text-[#000] text-left w-[100%] mt-[5px] ">
                              Currently uploaded:{" "}
                              <span
                                className="text-[#f4b233] text-[12px] cursor-pointer hover:underline text-left"
                                onClick={() =>
                                  downloadFileByUrl(
                                    form.getValues("storeListCsvUrl")!
                                  )
                                }
                              >
                                Stores.csv
                              </span>
                            </p>
                          )}
                          <FormMessage className="error-msg !w-full ml-[0px] mt-[5px] text-left text-[14px] [&>img]:mb-[1px] justify-start" />
                        </div>
                      </>
                    );
                  }}
                />
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
