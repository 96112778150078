import { useEffect, useRef, useState } from "react";
import Layout from "../layout/layout";
import { useNavigate } from "react-router-dom";
import BannerFooter from "../../assets/images/bannerFotter.png";
import FileIconBlack from "../../assets/images/FileIconBlack.svg";
import InfoCard from "../../components/infoCard";
import { Button } from "../../components/ui/button";
import { downloadFileByUrl, exceptionHandler } from "src/utils/helper";
import { useAlertStore } from "src/store/alert";
import api from "src/services/api";
import { ErrorMessages } from "src/constants/errorMessages";
import PageRoutes from "src/constants/pageRoutes";
import { HomeData } from "src/types/home.type";
import { usePromotionStore } from "src/store/promotion";
import Steps from "src/pages/home/components/steps";
import NewSubmissionConfirmationModal, {
  NewSubmissionConfirmationModalRef,
} from "src/modules/modal/newSubmissionConfirmationModal";
import ProgramHighlightCard from "./components/programHighlightCard";

const PROGRAM_FALLBACK_IMAGE = require("../../assets/images/banner/banner_img_four.jpg");

export default function Home() {
  const [data, setData] = useState<HomeData>({
    featuredProgram: [],
    highlightedProgram: [],
    heroBanner: [],
    policies: [],
  });
  let bannerData: any = [];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { setAlert } = useAlertStore();
  const { promotion } = usePromotionStore();
  const newSubmissionModalRef = useRef<NewSubmissionConfirmationModalRef>(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.home.data();
        if (res.status === 200) {
          setData(res.data);
        } else {
          throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
        }
      } catch (error) {
        exceptionHandler(error, setAlert, "destructive");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Layout loading={loading}>
            {data.heroBanner.length >= 4 ? (
        <section className="max-w-[1920px] mx-auto homeImgTabBorderWrapper">
          <div className="flex md:flex-row gap-[10px] flex-col ">
            {/* <div className=" "> */}
            {/* <div className="flex md:h-[350px] xl:h-[400px] xxl:h-[500px] xxl-1800:!h-[550px] xxl-1920:!h-[600px]"> */}
            <div className="flex w-100 md:w-1/2 md:h-[260px] md-820:h-[300px] mdtab:!h-[350px] lg-1200:!h-[400px] xl:!h-[420px] xxl:!h-[480px] 2xl:!min-h-[600px]">
              <img
                alt={data.heroBanner[0].alt}
                src={data.heroBanner[0].url}
                aria-label={bannerData?.[0]?.cardMedia?.thumbnailDesktopAriaLabel}
                className="w-full h-full"
              />
            </div>
            {/* </div> */}
            <div className="w-100 md:w-1/2 flex flex-col gap-[10px] ">
              <div className="flex md:h-[125px] md-820:h-[145px] mdtab:!h-[170px] lg-1200:!h-[195px] xl:!h-[205px] xxl:!h-[235px] 2xl:!h-[295px]">
                <img
                  alt={data.heroBanner[1].alt}
                  src={data.heroBanner[1].url}
                  aria-label={bannerData?.[1]?.cardMedia?.thumbnailDesktopAriaLabel}
                  className="w-full"
                />
              </div>
              <div className="flex sm:flex-row gap-[10px] md:h-[125px] md-820:h-[145px] mdtab:!h-[170px] lg-1200:!h-[195px] xl:!h-[205px] xxl:!h-[235px] 2xl:!h-[295px]">
                <div className="flex w-1/2">
                  <img
                    alt={data.heroBanner[2].alt}
                    src={data.heroBanner[2].url}
                    aria-label={bannerData?.[2]?.cardMedia?.thumbnailDesktopAriaLabel}
                    className="w-full"
                  />
                </div>
                <div className="flex w-1/2">
                  <img
                    alt={data.heroBanner[3].alt}
                    src={data.heroBanner[3].url}
                    aria-label={bannerData?.[3]?.cardMedia?.thumbnailDesktopAriaLabel}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <section className="flex flex-col bg-[#F4F4F4] my-[0px] sm:flex sm:flex-col gap-[60px] w-full">
        <div className="flex flex-col px-[45px] container py-[80px]">
          <h2 className="text-[20px] font-GothamBold 3sx:text-[24px] xs:text-[32px] md:text-[28px] lg:text-[40px] xl:text-[40px]  leading-[25px] 3xs:leading-[30px] xs:leading-[42px] md:leading-[30px] lg:leading-[45px] xl:leading-[50px] text-[#000] m-0 flex items-center mb-5 capitalize">
            Get Started
          </h2>
          <p className="font-sans text-[14px] mb-[60px]">
            We have an incredible opportunity to connect with our home consumers in a meaningful way. ​Program
            opportunities are rooted in shopper insights and designed to attract, engage, educate and connect with our
            consumers. Follow the steps below to start your application today!
          </p>
          <Steps />
        </div>
      </section>

      {/* banner end here*/}
      {(Array.isArray(data?.highlightedProgram) && data.highlightedProgram.length && (
        <section className="flex flex-col my-[0px]  sm:flex sm:flex-col gap-[60px] border-b-[1px] w-full">
          <div className="flex flex-col gap-[60px] px-[45px] container py-[80px]">
            <div className={"flex flex-col"}>
              <h2 className="text-[20px] font-GothamBold 3sx:text-[24px] xs:text-[32px] md:text-[28px] lg:text-[40px] xl:text-[40px]  leading-[25px] 3xs:leading-[30px] xs:leading-[42px] md:leading-[30px] lg:leading-[45px] xl:leading-[50px] text-[#000] m-0 flex items-center mb-5 capitalize">
                {"Program highlights"}
              </h2>
              <p className="font-sans text-[14px]">
                Where featuring an innovation, a limited time offer or incentive, or building your brand, The Beer Store
                has a program to amplify your brand purpose.
              </p>
            </div>
            <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 mdtab:grid-cols-4 gap-[30px]">
              {data.highlightedProgram?.slice(0, 4).map((item, index) => (
                <div key={item.id} className="basis-full md:basis-1/4">
                  <div className="main-info md:py-0">
                    <ProgramHighlightCard
                      key={item.id}
                      badgeText={item.category}
                      title={item.name}
                      description={item.description}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center">
              <Button
                variant={"secondary"}
                className="uppercase py-[15px]  px-[30px] gap-[10px] rounded-[30px] font-GothamBold text-[10px] leading-[20px] sm:text-[10px] md:text-[12px] lg:text-[16px] xl:text-[16px] h-auto max-h-[50px]"
                onClick={newSubmissionModalRef.current?.promotionRoute}
              >
                Start your Promotion Submission
              </Button>
            </div>
          </div>
        </section>
      )) ||
        null}
      {/* opportunity highlights end here */}
      {/* previousSubmissionForm */}
      {(false && (
        <section className="px-[45px] container py-[80px]">
          <div className="flex flex-row items-center gap-[30px]">
            <div className={"flex flex-col  basis-1/2 gap-[30px] center flex-shrink-0"}>
              <h2 className="text-[20px]  3sx:text-[24px] xs:text-[32px] md:text-[28px] lg:text-[40px] font-GothamBold leading-[25px] 3xs:leading-[30px] xs:leading-[42px] md:leading-[30px] lg:leading-[45px] xl:leading-[50px] text-[#000] m-0 flex items-center mb-5 capitalize">
                {"Continue Your Previous Promotion"}
              </h2>
              <p className="font-sans text-[12px] sm:text-[14px] leading-[20px]">
                Please revisit your promotional details and complete your application. We look forward to bringing your
                campaigns to life!
              </p>
              <div>
                <Button className="uppercase rounded-[30px] px-[30px] py-[15px] w-auto font-GothamBold text-[8px] sm:text-[10px] md:text-[12px] lg:text-[16px] xl:text-[16px] h-auto max-h-[50px]">
                  Continue Progress
                </Button>
              </div>
            </div>
            <div className="basis-1/2 flex flex-shrink-0">
              <img src={require("../../assets/images/split.png")} alt={"login"} className="h-full object-cover" />
            </div>
          </div>
        </section>
      )) ||
        null}
      {/* previousSubmissionForm end here*/}
      {/* Features */}
      {(Array.isArray(data?.featuredProgram) && data.featuredProgram.length && (
        <section className="bg-[#F4F4F4]">
          <div className="px-[45px] container py-[80px]">
            <div className="flex flex-col gap-[60px] w-full">
              <div className={"flex flex-col"}>
                <h2 className="text-[20px]  3sx:text-[24px] xs:text-[32px] md:text-[28px] lg:text-[40px] xl:text-[40px] font-GothamBold leading-[25px] 3xs:leading-[30px] xs:leading-[42px] md:leading-[30px] lg:leading-[45px] xl:leading-[50px] text-[#000] m-0 flex items-center mb-5 capitalize">
                  {"Don't miss out on these featured programs"}
                </h2>
                <p className="font-sans text-[16px] leading-[24px]">
                Opportunities are booked first come, first serve. Don’t miss out. Get started and secure your booking now.
                </p>
              </div>
              {/* <div className={`grid grid-cols-1 xs:grid-cols-2 md:grid-cols-${data?.featuredProgram?.length} gap-6`}> */}
              <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-[30px]">
                {data.featuredProgram?.slice(0, 3)?.map((item, data) => (
                  <div key={item.id} className="basis-full md:basis-1/3">
                    <div className="main-info md:py-0">
                      <InfoCard
                        badgeText="Featured"
                        from="home"
                        title={item.name}
                        description={item?.description}
                        image={{
                          url: item.image || PROGRAM_FALLBACK_IMAGE,
                          alt: item.name,
                          width: 355,
                          height: 232,
                        }}
                        className={`equal-h flex-col flex shadow-none rounded-[10px] border-[1px] overflow-hidden [&_.info-content]:py-[30px] [&_.info-content]:!px-[30px] [&_.info-content>div>h2]:mb-[20px] [&_.info-content>div>h2]:!leading-[24px] md:[&_.info-content>div>h2]:!leading-[30px] [&_.info-content>]:.eq-height [&>div>div>a]:font-bold [&>div>div>a]:uppercase [&>div>img]:rounded-[10px] [&_.info-content>div>h2]:text-[16px] md:[&_.info-content>div>h2]:text-[20px] [&_.info-content>div>h2]:font-medium md:[&_.info-content>div>h2]:font-bold 
                        [&_h3]:text-[18px]
                        [&_h3]:leading-[28px]
                        [&_h3]:font-GothamMedium
                        md:[&_.info-header]:max-h-[140px]
                        md:[&_.info-image]:min-h-[140px]
                        md-820:[&_.info-header]:max-h-[160px]
                        md-820:[&_.info-image]:min-h-[160px]
                        mdtab:[&_.info-header]:!max-h-[200px]
                        mdtab:[&_.info-image]:!min-h-[200px]
                        lg-1200:[&_.info-header]:!max-h-[250px]
                        lg-1200:[&_.info-image]:!min-h-[250px]
                        xxl:[&_.info-header]:!max-h-[316px]
                        xxl:[&_.info-image]:!min-h-[316px]
                        [&_p]:text-[14px]
                        [&_img]:text-[14px]
                        [&_img]:truncate [&_img]:overflow-hidden
                        [&_p]:leading-[20px]
                        [&_p]:font-sans
                        [&_.info-header]:relative
                        [&_.info-header]:overflow-hidden
                        [&_.info-description]:min-h-[30px]
                        // [&_.info-description]:!h-auto
                        [&_.info-description]:text-ellipsis
                        [&_.info-description]:line-clamp-2
                        [&>div>img]:absolute [&>div>img]:top-0 [&>div>img]:left-0 [&>div>img]:object-contain
                        [&_.info-title]:max-w-full 3xs:[&_.info-title]:max-w-[250px] md:[&_.info-title]:max-w-full info-height  [&_button]:!w-auto bg-white`}
                        // buttonLink={`/`}
                        // buttonText={"Apply now"}
                        // buttonAriaLabel={"Apply now"}
                        // index={itm + 1}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )) ||
        null}
      {/* Features end here */}

      {/* Merchandising Section */}
      {data.policies.length ? (
        <section
          className={`flex flex-wrap px-[45px] container ${data.featuredProgram.length ? "pt-[80px]" : "pt-[0px]"} pb-[80px] sm:flex flex-row border-b-2`}
        >
          <div className="flex flex-col gap-[60px] w-full">
            <div className={"flex flex-col"}>
              <h2 className="text-[20px] font-GothamBold 3sx:text-[24px] xs:text-[32px] md:text-[28px] lg:text-[40px]  leading-[25px] 3xs:leading-[30px] xs:leading-[42px] md:leading-[30px] lg:leading-[50px] text-[#000] m-0 flex items-center mb-5">
                {"Merchandising Policies"}
              </h2>
              {/* <p className="font-sans text-[16px] leading-[24px]">
                Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.
              </p> */}
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-[30px]">
              {data.policies.map((item) => (
                <div
                  className="flex flex-row gap-4 lg:gap-[20px] w-full md:max-w-[320px] mdtab:max-w-[335px] h-full max-h-[64px] cursor-pointer border border-[#D6D6D6] p-[20px] justify-between rounded-[10px] "
                  onClick={() => downloadFileByUrl(item.url)}
                  key={item.id}
                >
                  <span className="text-[16px] leading-[24px] text-[#000] font-GothamBold capitalize overflow-hidden max-w-[257px] text-ellipsis whitespace-nowrap">
                    {item.name}
                  </span>
                  <img
                    className="w-[15px] h-[20px] xl:w-[18px] xl:h-[24px] flex-shrink-0"
                    src={FileIconBlack}
                    alt="downloadIcon"
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : null}
      {/* Merchandising Section */}

      {/* FooterBanner */}
      {/* <section className=" mx-auto relative homeImgTabBorderWrapper ">
        <img
          src={BannerFooter}
          alt="BannerFooter"
          className="object-cover w-full h-[150px] md:h-auto lg:min-h-[350px] lg:h-auto  xl:h-auto"
        />
        <div className="centered w-full">
          <div className="flex flex-col  items-center gap-[20px] md:gap-[15px] lg:gap-[30px]">
            <h2 className="text-[18px] leading-normal sm:text-[24px] sm:leading-[30px] md:text-[26px] md:leading-[32px] lg:text-[42px] lg:leading-[48px] xl:text-[48px] xl:leading-[60px] text-white font-GothamMedium">
              Questions? Reach out to us!
            </h2>
            <div>
              <Button
                className="rounded-[30px] uppercase font-GothamMedium font-bold text-[8px] sm:text-[10px] md:text-[12px] lg:text-[16px] xl:text-[16px] leading-[20px] md:py-[5px] lg:py-[15px] md:px-[15px] lg:px-[30px] h-auto max-h-[50px]"
                onClick={() => navigate(PageRoutes.CONTACT_US)}
              >
                Contact us
              </Button>
            </div>
          </div>
        </div>
      </section> */}
      {/* FooterBanner end here */}
      <NewSubmissionConfirmationModal navigate={navigate} ref={newSubmissionModalRef} />
    </Layout>
  );
}
